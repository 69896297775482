<template>
    <section class="section container mt-5">
        <div class="row text-center mt-5">
            <div class="col-lg-6 offset-lg-3 mx-auto mt-5">
                <form @submit.prevent="onSubmit">
                    <input class="form-control form-control-lg" type="email" placeholder="email@example.com"
                        v-model="email">
                    <button type="submit" :disabled="disabled" class="btn btn-lg btn-dark mt-3">Submit</button>
                </form>
                <p class="text-danger" v-if="error">{{ error }}</p>
            </div>
        </div>

    </section>
</template>
  
<script>
import { useUserStore } from '@/stores/user'
import { getUserId, updateUserEmail } from '@/firebase'

export default {
    name: 'UserEmail',
    setup () {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },
    async created () { },
    data () {
        return {
            uid: getUserId(),
            error: null,
            email: this.userStore.user.email || '',
            disabled: (this.userStore.user.email && this.userStore.user.email.length) >= 5 ? false : true
        }
    },
    watch: {
        // whenever question changes, this function will run
        email () {
            if (this.email && this.email.length >= 5) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        }
    },
    methods: {
        async onSubmit () {
            this.error = null
            try {
                await updateUserEmail(this.email)
                this.$router.push('/dashboard')
            } catch (e) {
                this.error = e.message
            }
        }
    }
}
</script>
  
<style scoped>
.form-control,
.form-control-lg {
    border-radius: 0;
}
</style>