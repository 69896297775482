
import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'

import UserDashboard from '@/components/UserDashboard'
import UserEmail from '@/components/UserEmail'
import UserLogin from '@/components/UserLogin'

export const router = createRouter({
	history: createWebHistory(),
	routes: [{
		path: '/login',
		name: 'UserLogin',
		component: UserLogin
	},
	{
		path: '/email',
		name: 'UserEmail',
		component: UserEmail,
		meta: {
			auth: true
		}
	},
	{
		path: '/dashboard',
		name: 'UserDashboard',
		component: UserDashboard,
		meta: {
			auth: true
		}
	},
	{ path: '/:pathMatch(.*)*', redirect: '/dashboard' }]
})


router.beforeEach(async (to) => {
	const userStore = useUserStore()

	if (to.meta.auth && !userStore.auth) {
		return '/login'
	}

	if (to.path !== '/email' && userStore.auth && (!userStore.auth.email || userStore.auth.email === 'retry@pichsenmeister.com')) {
		return '/email'
	}

	if (userStore.auth && to.path === '/login') {
		return '/dashboard'
	}
})
