<template>
    <section class="mt-5">
        <div class="alert alert-info m-4" role="alert">
            <p>🎁 TikTok Wrapped* is brought to you by <a href="https://reclip.pro"
                    target="_blank"><strong>reclip.pro</strong></a>,
                a full service solution for content creators.</p>
            <p>Automatically download, save, and share your TikTok videos without watermarks. Ready to streamline your
                content sharing process and reach a wider audience?</p>
            <a href="https://reclip.pro" target="_blank"><strong>Check it out now!</strong></a>
            <br><br><small>* We are not affiliated with TikTok, Douyin or Bytedance.</small>
        </div>
    </section>
</template> 

<script>

export default {
    name: 'TrialHeader'
}
</script>

<style scoped>
p,
a {
    font-size: 1.2rem;
    font-weight: bold;
}
</style>