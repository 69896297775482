<template>
  <nav-bar></nav-bar>
  <!-- <nav-pills></nav-pills> -->
  <router-view></router-view>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
// import NavPills from '@/components/NavPills.vue'

export default {
  name: "App",
  components: { NavBar }
}
</script>

<style>
body {
  background: #f6f6f6;
}

#app {
  font-family: "Nunito", sans-serif;
  color: #000;
  background: #f6f6f6;
}

.btn {
  border-radius: 0;
}

.btn-dark {
  --bs-btn-bg: #000;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.text-sm {
  font-size: .8rem;
}

.text-lg {
  font-size: 1.2rem;
}

.text-instagram {
  color: #C13584;
}

.text-discord {
  color: #5865F2;
}

.text-drive {
  color: #F4B400;
}

.text-youtube {
  color: #FF0000;
}
</style>
