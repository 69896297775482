// loading the firebase config depending on environment set in config
import { initializeApp } from 'firebase/app'
import { getFirestore, doc, getDoc, getDocs, updateDoc, collection, query, orderBy } from 'firebase/firestore'
import { getAuth, signInWithCustomToken, updateProfile, updateEmail, sendEmailVerification, signOut } from 'firebase/auth'

const host = window.location.hostname

const devConfig = {
    apiKey: 'AIzaSyDI0LCBMQvvVOlbDc7dCWOwXuPZaGVl-_8',
    authDomain: 'reclip-wrapped.firebaseapp.com',
    projectId: 'reclip-wrapped',
    databaseURL: 'https://reclip-wrapped.firebaseio.com',
}
const prodConfig = {
    apiKey: "AIzaSyDI0LCBMQvvVOlbDc7dCWOwXuPZaGVl-_8",
    authDomain: "wrapped.reclip.pro",
    projectId: "reclip-wrapped",
    appId: "1:1050559291748:web:243c1479557424e99e9a71",
    databaseURL: 'https://reclip-wrapped.firebaseio.com',
}

let config = prodConfig
let prod = true
if (host === 'localhost' || host === 'dpi.ngrok.io') {
    prod = false
    console.info('firebase: DEV')
    config = devConfig
} else {
    console.info('firebase: PROD')
}

export const env = prod ? 'PROD' : 'DEV'
export const apiHost = env === 'PROD' ? '/api' : 'https://dpi.ngrok.io/reclip-wrapped/us-central1/main/api'

const app = initializeApp(config)

export const auth = getAuth(app)
auth.useDeviceLanguage()

export const db = getFirestore(app)

export const getUserId = () => {
    if (!auth.currentUser) return null
    return auth.currentUser.uid.split(':', 2)[1]
}

export const getUser = async () => {
    if (!auth.currentUser) return null

    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) return null

    return docSnap.data()
}

export const updateUser = async (diff) => {
    if (!auth.currentUser) return null

    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    await updateDoc(docRef, diff)
}

export const signInWithTikTok = async (token) => {
    const res = await signInWithCustomToken(auth, token)
    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    const snap = await getDoc(docRef)
    if (snap.exists()) {
        const data = snap.data()
        await updateProfile(auth.currentUser, {
            displayName: data.display_name
        })
    }
    return res
}

export const logout = async () => {
    await signOut(auth)
}

export const updateUserEmail = async (email) => {
    await updateEmail(auth.currentUser, email)
    await sendEmailVerification(auth.currentUser)
    const uid = getUserId()
    const userRef = doc(db, 'users', uid)
    await updateDoc(userRef, { email })
}

export const getVideos = async () => {
    const uid = getUserId()
    const docRef = collection(db, `users/${uid}/videos`)
    const q = query(docRef, orderBy('create_time', 'desc'))
    const snap = await getDocs(q)
    return snap.docs.map((doc) => {
        return doc.data()
    })
}

export const getProviders = async () => {
    const uid = getUserId()
    const docRef = collection(db, `users/${uid}/providers`)
    const snap = await getDocs(docRef)
    const providers = {}
    snap.docs.forEach((doc) => {
        providers[doc.id] = doc.data()
    })
    return providers
}

