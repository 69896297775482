<template>
  <section class="mt-5 section container">

    <trial-header></trial-header>

    <div class="m-4 p-4 row row--provider" v-if="userStore.user">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-0">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-2 img-round" :src="userStore.user.avatar_url" alt="Card image cap">
              <div class="mb-5 small">{{ userStore.user.display_name }}</div>
              <div class="number no-line">2023</div>
              <div class="number no-line">WRAPPED
              </div>
              <div class="mt-2">for TikTok creators</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-0']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-profile.jpg"
          :href="urls['card-0']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="videos.length">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-1">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/1F3AC.svg" alt="Card image cap">
              <div>You posted</div>
              <div>a total of</div>
              <div class="number">{{ videos.length }}</div>
              <div>videos on</div>
              <div>TikTok this year.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-1']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-videos.jpg"
          :href="urls['card-1']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="duration > 0">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-10">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/23F3.svg" alt="Card image cap">
              <div>You filmed for</div>
              <div class="number">{{ duration }}</div>
              <div>seconds.</div>
              <div class="small">That's <span v-if="hours > 0">{{ hours }} hours and </span>{{ minutes }} minutes.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-10']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-watchtime.jpg"
          :href="urls['card-10']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="views > 0">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-2">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/1F440.svg" alt="Card image cap">
              <div>All your videos</div>
              <div>have been viewed</div>
              <div class="number">{{ views.toLocaleString() }}</div>
              <div>times.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-2']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-views.jpg"
          :href="urls['card-2']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="likes > 0">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-3">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/2764.svg" alt="Card image cap">
              <div>You're quite</div>
              <div>popular:</div>
              <div class="number">{{ likes.toLocaleString() }}</div>
              <div>is how many</div>
              <div>likes you got.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-3']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-likes.jpg"
          :href="urls['card-3']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="comments > 0">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-4">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/E263.svg" alt="Card image cap">
              <div>People had a lot</div>
              <div>to say.</div>
              <div class="number">{{ comments.toLocaleString() }}</div>
              <div>comments were</div>
              <div>posted on your</div>
              <div>videos.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-4']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-comments.jpg"
          :href="urls['card-4']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="shares > 0">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-5">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top mb-3" src="@/assets/1F4E8.svg" alt="Card image cap">
              <div>Sharing is caring.</div>
              <div class="number">{{ shares.toLocaleString() }}</div>
              <div>times someone</div>
              <div>shared one of</div>
              <div>your videos.</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-5']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-shares.jpg"
          :href="urls['card-5']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="mostViews.view_count">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-6">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top card-img-top-sm mb-3" src="@/assets/1F3C6.svg" alt="Card image cap">
              <div class="small">Most viewed video:</div>
              <img :src="mostViews.cover_image_url" class="cover mt-3">
              <div>{{ mostViews.view_count.toLocaleString() }} views</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-6']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-most-views.jpg"
          :href="urls['card-6']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="mostLikes.like_count">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-7">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top card-img-top-sm mb-3" src="@/assets/1F3C5.svg" alt="Card image cap">
              <div class="small">Most liked video:</div>
              <img :src="mostLikes.cover_image_url" class="cover mt-3">
              <div>{{ mostLikes.like_count.toLocaleString() }} likes</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-7']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-most-likes.jpg"
          :href="urls['card-7']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="mostComments.comment_count">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-8">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top card-img-top-sm mb-3" src="@/assets/1F396.svg" alt="Card image cap">
              <div class="small">Most commented video:</div>
              <img :src="mostComments.cover_image_url" class="cover mt-3">
              <div>{{ mostComments.comment_count.toLocaleString() }} comments</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-8']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-most-comments.jpg"
          :href="urls['card-8']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <div class="m-4 p-4 row row--provider" v-if="mostShares.share_count">
      <div class="col-12 d-flex justify-content-center">
        <div class="card d-flex justify-content-center align-items-center" id="card-9">
          <div class="card-body d-flex justify-content-center align-items-center">
            <div>
              <img class="card-img-top card-img-top-sm mb-3" src="@/assets/1F947.svg" alt="Card image cap">
              <div class="small">Most shared video:</div>
              <img :src="mostShares.cover_image_url" class="cover mt-3">
              <div>{{ mostShares.share_count.toLocaleString() }} shares</div>
            </div>
          </div>
          <div class="card-footer">
            wrapped.reclip.pro
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-4" v-if="urls['card-9']">
        <a class="btn btn-lg btn-dark" download="tiktok-wrapped-by-reclip-pro-most-shares.jpg"
          :href="urls['card-9']">Download</a>
      </div>
      <div class="col-12 text-center mt-4 d-flex justify-content-center align-items-center" v-else>
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="ms-2 text-secondary"> generating image</div>
      </div>
    </div>

    <footer-nav></footer-nav>

  </section>
</template>

<script>

import { useUserStore } from '@/stores/user'
import { useVideoStore } from '@/stores/video'
import { getUserId, getProviders, getVideos, apiHost, env } from '@/firebase'
import FooterNav from '@/components/FooterNav.vue'
import TrialHeader from '@/components/TrialHeader.vue'
import * as htmlToImage from 'html-to-image'

export default {
  name: 'UserDashboard',
  components: { FooterNav, TrialHeader },
  setup () {
    const userStore = useUserStore()
    const videoStore = useVideoStore()

    return {
      userStore,
      videoStore,
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.poll) this.pollImages()
    })

  },
  async created () {
    await this.loadProviders()
    await this.loadVideos()
  },
  data () {
    return {
      uid: getUserId(),
      videos: [],
      providers: {},
      apiHost,
      env,
      likes: 0,
      mostLikes: {},
      comments: 0,
      mostComments: {},
      views: 0,
      duration: 0,
      minutes: 0,
      hours: 0,
      mostViews: {},
      shares: 0,
      mostShares: {},
      urls: {},
      poll: null
    }
  },
  methods: {
    async pollImages () {
      if (this.poll) return
      this.poll = setInterval(async () => {
        const len = Object.keys(this.urls).length
        console.log('polling', len)
        if (len < 11) {
          for (let i = 0; i < 11; i++) {
            if (!this.urls[`card-${i}`]) {
              const dataUrl = await this.toImg(`card-${i}`)
              if (dataUrl) this.urls[`card-${i}`] = dataUrl
            }
          }
        } else {
          clearInterval(this.poll)
        }
      }, 1000)
    },
    async loadProviders () {
      this.providers = await getProviders()
    },
    async loadVideos () {
      this.videos = await getVideos()
      this.videos.forEach(video => {
        this.likes += video.like_count
        this.comments += video.comment_count
        this.views += video.view_count
        this.shares += video.share_count
        this.duration += video.duration

        this.minutes = Math.floor(this.duration / 60)
        this.hours = Math.floor(this.minutes / 60)

        if (video.like_count > (this.mostLikes.like_count || 0)) {
          this.mostLikes = video
        }
        if (video.comment_count > (this.mostComments.comment_count || 0)) {
          this.mostComments = video
        }
        if (video.view_count > (this.mostViews.view_count || 0)) {
          this.mostViews = video
        }
        if (video.share_count > (this.mostShares.share_count || 0)) {
          this.mostShares = video
        }
      })


    },
    split (str) {
      const lineLength = 97

      let title = ''

      str.split(' ').forEach(word => {
        if (title.length + word.length + 1 < lineLength) title += word + ' '
      })

      return title.trim() + ' ...'
    },
    async toImg (id) {
      const node = document.getElementById(id)
      if (!node) return null

      try {
        const dataUrl = await htmlToImage.toPng(node)
        return dataUrl
      } catch (error) {
        console.error(error)
      }
      return null
    }
  }
}
</script>

<style scoped>
.row--provider {
  background: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.bi {
  font-size: 2rem;
}

.bi-sm {
  font-size: 1rem;
}

.bi-info-circle {
  font-size: 1rem;
  line-height: 2rem;
}

.info {
  font-size: 1rem;
  font-weight: normal;
}

.mw-75 {
  max-width: 75%;
}

.card {
  width: 360px;
  height: 640px;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.5;
  text-align: center;
  font-family: 'Lato', 'sans-serif';
  border: none;
}

.card .number {
  font-size: 54px;
  font-weight: 900;
}

.small {
  font-size: 24px;
}

.x-small {
  font-size: 12px;
}
.card-img-top {
  width: 128px;
  height: 128px;
}

.card-img-top-sm {
  width: 96px;
  height: 96px;
}

.cover {
  max-width: 60%;
  border-radius: 10px;
}

 #card-10 {
  background: rgb(235,200,18);
background: linear-gradient(90deg, rgba(235,200,18,1) 0%, rgba(176,253,29,1) 50%, rgba(252,232,69,1) 100%);
} 

#card-1 {
  background: rgb(2, 0, 36);
  background: linear-gradient(135deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
  color: white;
}

#card-2 {
  background: rgb(255, 0, 0);
  background: linear-gradient(135deg, rgba(255, 0, 0, 1) 0%, rgba(212, 3, 81, 1) 35%, rgba(255, 41, 150, 1) 100%);
}

#card-3 {
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(224, 253, 45, 1) 100%);
}

#card-4 {
  background: rgb(131, 58, 180);
  background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 121, 69, 1) 100%);

}

#card-5 {
  background: rgb(199, 110, 240);
  background: radial-gradient(circle, rgba(199, 110, 240, 1) 0%, rgba(91, 0, 161, 1) 100%);
}

#card-6 {
  background: rgb(14, 0, 255);
  background: linear-gradient(135deg, rgba(14, 0, 255, 1) 0%, rgba(27, 102, 140, 1) 35%, rgba(30, 217, 255, 1) 100%);
  color: white;
}

#card-7 {
  background: rgb(100, 1, 161);
  background: linear-gradient(135deg, rgba(100, 1, 161, 1) 0%, rgba(134, 0, 171, 0.9445028011204482) 35%, rgba(221, 20, 247, 1) 100%);

  color: white;
}

#card-8 {
  background: rgb(0, 167, 72);
  background: linear-gradient(135deg, rgba(0, 167, 72, 1) 0%, rgba(14, 176, 5, 1) 35%, rgba(20, 247, 42, 1) 100%);
}

#card-9 {
  background: rgb(63, 94, 251);
  background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%);
  color: white;
}

#card-0 {
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(151, 253, 45, 1) 100%);
}

.img-round {
  border-radius: 50%;
}

.no-line {
  line-height: 54px;
}

.card-footer {
  border: none;
  background: none;
  font-size: 16px;
}
</style>