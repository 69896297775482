<template>
    <div class="row mt-5 mb-5">
        <div class="col text-center text-secondary">
            <a href="#" @click="logout()">Log out</a>
            <span class="mx-2">|</span>
            <a href="mailto:hello@reclip.pro" target="_blank">Contact</a>
            <span class="mx-2">|</span>
            <a href="https://reclip.pro/terms" target="_blank">Terms of Service</a>
            <span class="mx-2">|</span>
            <a href="https://reclip.pro/privacy" target="_blank">Privacy Policy</a>
        </div>
    </div>
</template>
  
<script>
// import { useUserStore } from '@/stores/user'
import { logout } from '@/firebase'

export default {
    name: 'FooterNav',
    methods: {
        async logout () {
            await logout()
        },
    },
}
</script>
  
<style scoped></style>