<template>
  <section class="section container mt-5">
    <div class="row text-center mt-5">
      <div class="col-lg-6 offset-lg-3 mx-auto mt-5">
        <div>
          <button v-if="loading" disabled class="btn btn-dark btn-lg">
            Logging you in ...
          </button>
        </div>
        <p class="text-info mt-4" v-if="loading">We are importing your videos.<br>This might take a few seconds.</p>

        <a v-else :href="apiHost + '/tiktok/login'" class="btn btn-dark btn-lg">
          Login with TikTok
        </a>
        <p class="text-danger" v-if="error">{{ error }}</p>
      </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col-lg-6 offset-lg-3 mx-auto">
        <p class="text-secondary">By signing up I agree to the <br>
          <a href="https://reclip.pro/terms" target="_blank">Terms of Service</a>
          and
          <a href="https://reclip.pro/privacy" target="_blank">Privacy Policy</a>
        </p>
      </div>
    </div>

  </section>
</template>

<script>
import { signInWithTikTok, apiHost } from '@/firebase'

export default {
  name: 'UserLogin',
  async created () {
    const token = this.$route.query.token
    if (token) {
      this.loading = true
      try {
        await signInWithTikTok(token)
      } catch (e) {
        this.error = e.message
      }
    }
  },
  data () {
    return {
      loading: false,
      error: null,
      apiHost
    }
  },
  methods: {}
}
</script>

<style scoped></style>